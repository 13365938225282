.container {
  max-width: 1390px;
  margin: auto;
}

.entertiment {
  margin-bottom: 80px;
}

.entertiment img {
  z-index: 0;
  height: 100%;
  width: 100%;
  border-radius: 24px;
}

.entertiment_label {
  margin: 0px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  margin-top: 50px;
}

@media (max-width: 400px) {
  .entertiment_label h2 {
    font-size: 35px;
  }
}

.counter {
  color: #ffffff;
  -webkit-transform: rotate(20deg);
          transform: rotate(20deg);
  padding-top: 25px;
  text-align: center;
  background-color: #f97caf;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  font-size: 28px;
  line-height: 32px;
}

.entertiment_row {
  margin: 50px 0px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.entertiment_card {
  margin: 12px 12px;
  height: 320px;
  width: 320px;
  border-radius: 24px;
  position: relative;
  z-index: 1;
}

.entertiment_card img {
  border-radius: 24px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.gradiant {
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 24px;
  border-radius: 24px;
  z-index: 2;
  height: 100%;
  width: 100%;
  /* background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.6) 82%,
    rgba(0, 0, 0, 0.85) 99%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.6) 82%,
    rgba(0, 0, 0, 0.85) 99%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.01) 1%,
    rgba(0, 0, 0, 0.6) 82%,
    rgba(0, 0, 0, 0.85) 99%
  ); */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#d9000000',GradientType=0 );
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

.entertiment_card_label {
  z-index: 3;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  max-width: 175px;
  text-shadow: 2px 2px 5px black;
}

.price {
  margin-top: 6px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  color: #ffffff;
}

.more_btn {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
  border-radius: 12px;
  z-index: 3;
  height: 32px;
  width: 80px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  top: 261px;
  left: 216px;
  background-color: #4f5c61;
  padding: 5px 12px;
  color: #ffffff;
}

.gradiant:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), color-stop(1%, rgba(0, 0, 0, 0.01)), color-stop(70%, rgba(0, 0, 0, 0.7)), color-stop(99%, rgba(0, 0, 0, 0.85)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.01) 1%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0.85) 99%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#d9000000',GradientType=0 );
}

.gradiant:hover .more_btn {
  background-color: #ffffff;
  color: #4f66cf;
}

.entertiment_button {
  margin-top: 50px;
  text-align: center;
  cursor: pointer;
}

.entertiment_button a {
  border-radius: 24px;
  padding: 15px 85px;
  background: #4f66cf;
  display: inline-block;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

._disabled {
  display: none;
}

.entertiment_button a:hover {
  color: #4f66cf;
  background-color: #dfe5ff;
}

/************************************/
@media (max-width: 768px) {
  .entertiment_row {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (max-width: 500px) {
  .main_filter {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .entertiment_label h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .entertiment_label h2 span {
    display: block;
  }
  .entertiment_row {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (max-width: 350px) {
  .entertiment_card {
    width: 300px;
  }
}
