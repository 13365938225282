.notification {
    opacity: 0;
    z-index: -1;
    width: 310px;
    position: absolute;
    background-color: #5bd262;
    border: 4px solid #78e87e;
    padding: 25px;
    color: #ffffff;
    font-family: "Rubik";
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    box-shadow: 0px 14px 19px rgba(51, 143, 57, 0.2);
    border-radius: 24px;
    transition-duration: 1s;
    top: 100vh;
    left: calc(50% - 155px);
   
  }
  
  .notification_active {
    width: 310px;
    position: absolute;
    background-color: #5bd262;
    border: 4px solid #78e87e;
    padding: 25px;
    color: #ffffff;
    font-family: "Rubik";
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    box-shadow: 0px 14px 19px rgba(51, 143, 57, 0.2);
    border-radius: 24px;
    transition-duration: 1s;
    left: calc(50% - 155px);
    
    z-index: 10;
    opacity: 1;
    top: 75vh;
  }