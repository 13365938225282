.container {
  max-width: 1390px;
  margin: auto;
}
.footer {
  margin-top: 80px;
  background-color: #4f66cf;
  color: #ffffff;
}
.footer-row {
  padding-top: 65px;
  display: flex;
  justify-content: space-between;
}

.footer-infp-label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 30px;
}
.phone-number {
  font-family: "Rubik";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-decoration: none;
  color: #4f66cf;
  background-color: #ffffff;
  padding: 15px 40px;
  border-radius: 24px;
  transition-duration: 0.3s;
}
.phone-number:hover {
  background-color: #8b9df0;
  color: #ffffff;
}
.footer-info-text {
  margin-top: 34px;
}
.footer-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.footer-socials {
  max-width: 690px;
  margin-bottom: 240px;
}
.footer-socials-text {
  margin-left: 15px;
  margin-bottom: 30px;
}
.footer-socials-list {
  display: flex;
  flex-wrap: wrap;

  li {
    font-family: "Rubik";
    margin: 12px;
    width: 320px;
    background-color: #7085e4;
    border-radius: 24px;
    a {
      img {
        margin-right: 15px;
      }
      padding: 15px 24px;

      display: flex;
      align-items: center;
      text-decoration: none;
      color: #ffffff;
    }
  }
  li:hover {
    transition-duration: 0.3s;
    background-color: #8b9df0;
  }
}
/************************************/
@media (max-width:768px) {
 
  .footer-socials-list{
    width: 320px;
  }
}


@media (max-width: 500px) {
  .footer-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-info-text {
    display: none;
  }
  .footer-info {
    text-align: center;
   
  }
  .footer-infp-label {
    margin-bottom: 50px;
    span {
      display: none;
    }
  }
  .footer-socials {
    margin-top: 50px;
    text-align: center;
  }
  .footer-socials-list {
    justify-content: center;
    li {
      width: 300px;
      font-size: 24px;
      line-height: 32px;
    }
  }
  .footer-socials-text {
    margin-bottom: 15px;
  }
}

/************************************/
.mobile-call {
  display: none;
}
@media (max-width: 500px) {
  .mobile-call {
    display: block;
    img {
      height: 30px;
      width: 30px;
    }
    z-index: 5;
    background-color: #5bd262;
    border-radius: 50%;
    position: fixed;
    padding: 25px;
    top: 80%;
    left: 70%;
    border: 4px solid #78e87e;
  }
  .container {
    padding: 0px;
  }
}

@media (max-width: 325px) {
  .phone-number {
  padding: 15px 35px;}
}