.Admin form {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-left: 50px;
}

.Admin input {
  border: 1px solid black;
  width: 200px;
  margin: 5px 20px 10px;
}

.Admin input:hover {
  -webkit-box-shadow: 1px 1px 10px black;
          box-shadow: 1px 1px 10px black;
}

.Admin button {
  border: 1px solid #251919;
  width: 200px;
  margin: 5px 20px 10px;
}

.Admin button:hover {
  background-color: #003feb;
  color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.Admin label {
  display: inline;
}

.Admin p {
  margin-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Admin p input {
  width: 20px;
  margin: 5px;
}
