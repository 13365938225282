.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  opacity: 0;
  display: none;
}

.modal_active {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.Modal {
  height: 75vh;
  width: 1200px;
}

.swiper-slide {
  position: relative;
  border-radius: 24px;
}

.swiper-slide img {
  border-radius: 24px;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

.swiper-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 9;
}

.swiper-pagination-bullet {
  display: inline-block;
  border-radius: 50%;
  background: var(--swiper-pagination-bullet-inactive-color, #a1b1c2);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1);
}

.swiper-pagination-bullet-active {
  background: var(--swiper-pagination-bullet-active-inactive-color, #ffffff);
  width: var(--swiper-pagination-bullet-active-width, var(--swiper-pagination-bullet-size, 12px));
  height: var(--swiper-pagination-bullet-active-height, var(--swiper-pagination-bullet-size, 12px));
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 45px;
  left: 0;
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  background-image: url(../../public/img/icons/Back.png);
  background-repeat: no-repeat;
  position: absolute;
  top: 90%;
  left: 35px;
  width: 50px;
  height: 50px;
  display: block;
}

.swiper-button-next {
  background-image: url(../../public/img/icons/Next.png);
  left: 100px;
}

.swiper-button-next::after {
  content: " ";
}

.swiper-button-prev::after {
  content: " ";
}

@media (max-width: 500px) {
  .modal {
    display: none;
  }
  .modal_active {
    display: none;
  }
  .Modal {
    height: 100%;
    width: 100vh;
    -o-object-fit: fill;
       object-fit: fill;
  }
  .swiper {
    height: 100%;
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
}
