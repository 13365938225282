.Admin form {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 50px;
  }
  
  .Admin input {
    border: 1px solid black;
   width: 200px;
    margin: 5px 20px 10px;
    &:hover{
        box-shadow: 1px 1px 10px black;
    }
  }
  
  .Admin button {
    border: 1px solid rgb(37, 25, 25);
   width: 200px;
    margin: 5px 20px 10px;
    &:hover{
        background-color: rgb(0, 63, 235);
        color: white;
        transition: 0.3s;
    }
  }
  .Admin label{
    display: inline;
  }
  .Admin p{
    margin-left: 20px;
    input{
        width: 20px;
       margin: 5px;
    }
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }